import { objectIcons } from './objects'

export interface IFilterType {
  icon?: string
  id: string
  name: string
}

export const externalFilters: IFilterType[] = [
  {
    icon: '$fas-building',
    id: 'external-false',
    name: 'Internal'
  },
  {
    icon: '$fas-globe',
    id: 'external-true',
    name: 'External'
  }
]

export const typeFilters: IFilterType[] = [
  {
    icon: objectIcons.system,
    id: 'type-system',
    name: 'System'
  },
  {
    icon: objectIcons.actor,
    id: 'type-actor',
    name: 'Actor'
  },
  {
    icon: objectIcons.group,
    id: 'type-group',
    name: 'Group'
  },
  {
    icon: objectIcons.app,
    id: 'type-app',
    name: 'App'
  },
  {
    icon: objectIcons.store,
    id: 'type-store',
    name: 'Store'
  },
  {
    icon: objectIcons.component,
    id: 'type-component',
    name: 'Component'
  }
]

export const diagramFilters: IFilterType[] = [
  {
    icon: '$fas-project-diagram',
    id: 'diagram-exists',
    name: 'In a diagram'
  },
  {
    icon: '$custom-solid-repeat',
    id: 'flow-exists',
    name: 'In a flow'
  }
]

export const detailFilters: IFilterType[] = [
  {
    id: 'has-display-description',
    name: 'Displayed description'
  },
  {
    id: 'has-detailed-description',
    name: 'Detailed description'
  }
]
